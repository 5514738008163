import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseDimensionsExample } from './use-dimensions.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usedimensions"
    }}>{`useDimensions`}</h1>
    <p>{`Use this hook to debounce a value only updating it once within the given delay.
Useful if you need to request data when an input updates but don't want to fetch
the new data on every keystroke.`}</p>
    <p>{`This hook is heavily inspired by
`}<a parentName="p" {...{
        "href": "https://reach.tech/rect"
      }}>{`Reach's useRect hook`}</a>{`.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useRef } from 'react';
import { useDimensions } from '@fransvilhelm/hooks';

const SquareWithDimensions = () => {
  const ref = useRef(null);
  const rect = useDimensions(ref);
  return (
    <div ref={ref}>
      {rect?.width} px / {rect?.height} px
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseDimensionsExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseDimensionsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseDimensionsExample mdxType="UseDimensionsExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useDimensions`}</inlineCode>{` accepts one required parameter – a React ref object attached to
a DOM element – and two optional parameters related to observing dimensions
changes.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<Element>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ref object attached to a dom element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`observe`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set to true to subscribe to changes on the elements dimensions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`callback`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(rect: DOMRect) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback called every time the rect is updated`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useDimensions`}</inlineCode>{` returns an client rect object.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`rect`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`DOMRect \\| null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`rect object containing dimensions and position of the current element. This is the same object as one gets from `}<inlineCode parentName="td">{`element.getBoundingDOMRect()`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      